/**
 * Single DataLayer - Primary part
 * Placed in the <head>, pushes data to sdl object
 * Secondary part (singleDL-secondary.js) is placed in footer
 * @version 1.0.0
 * @author Vendula Frýzlová <vendula.fryzlova@avast.com>, Jaroslav Slaby <jaroslav.slaby@avast.com>
 * @copyright Avast Software s.r.o &copy;2022
 */

(function () {
	'use strict';
	if (window.sdl !== undefined) {
		try {
			if (/OptanonConsent=.*?(consentId=(.*?)(?=&))/.test(document.cookie)) {
				sdl.push({
					client: {
						cmpId: document.cookie.match(/OptanonConsent=.*?(consentId=(.*?)(?=&))/)[2]
					}
				})
			}

			//if (/(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/.test(document.cookie)) {
			if (/(?:NotLandingPage&groups=|groups=).*?(?=&|;|$)/.test(document.cookie)) { // safari fix
				sdl.push({
					// uncomment and remove fix when apple develops a working browser and not that shit safari (safari doesn't support ?<=, also they don't know what try-catch is)
					//consentGroups: document.cookie.match(/(?<=NotLandingPage&groups=|groups=).*?(?=&|;|$)/)[0].replace(/%3A/g, ':').replace(/%/g, ',').replace(/2C/g, ' ')
					consentGroups: document.cookie.match(/(?:NotLandingPage&groups=|groups=).*?(?=&|;|$)/)[0].replace(/NotLandingPage&groups=|groups=/, '').replace(/%3A/g, ':').replace(/%/g, ',').replace(/2C/g, ' ') // safari fix
				})
			}

			sdl.push({
				session: {
					countryCode: sdlObj.session.countryCode,
					secChUa: (function () {
						// Check Client Hints support. For now only by chromium based browsers (July 2022)
						if (navigator.userAgentData !== undefined && navigator.userAgentData.brands.length > 0) {
							return navigator.userAgentData.brands
						} else {
							return [];
						}
					})(),
					platform: window.avastGlobals.detect.os.name,
					mobile: (function () {
						// Check Client Hints support. For now only by chromium based browsers (July 2022)
						if (navigator.userAgentData !== undefined && navigator.userAgentData.brands.length > 0) {
							return navigator.userAgentData.mobile
						} else {
							return '-';
						}
					})()
				},
				server: {
					provider: 'CMS',
					platform: 'WEB',
					env: window.location.host.startsWith('web-preview') ? 'dev' : 'prod',
					dataCenter: '-',
					sdlVersion: '2021-05-05/v1'
				}
			});

			var URLParams = new URLSearchParams(window.location.search);

			// Remove unwanted URL parameters
			function removeUnwantedParamsFromUrl(URLParams) {
				var unwantedParams = ['ASWPARAM', 'aswparam', '_ga']
				for (var i = 0; i < unwantedParams.length; i++) {
					URLParams.delete(unwantedParams[i])
				}
				var URLWithoutUnwantedParams =  window.location.host + window.location.pathname + '?' + URLParams.toString()
				return URLWithoutUnwantedParams
			}

			sdl.push({
				event: 'screen',
				screen: {
					location: {
						url: window.location.search ? removeUnwantedParamsFromUrl(URLParams) : window.location.host + window.location.pathname,
						woParams: window.location.host + window.location.pathname,
						protocol: window.location.protocol.replace(':', ''),
						path: '/' + sdlObj.screen.locale + '/' + sdlObj.screen.path,
						hostname: sdlObj.screen.hostname,
						hash: window.location.hash,
						params: window.location.search ? Object.fromEntries(URLParams) : ''
					},
					locale: sdlObj.screen.locale,
					screenCategory: sdlObj.screen.screenCategory,
					title: document.title,
					type: sdlObj.screen.fileName.includes('error-page.php') ? 'error' : sdlObj.screen.type,
					name: sdlObj.screen.name,
					lineOfBusiness: sdlObj.screen.lineOfBusiness,
					screenId: sdlObj.screen.screenId
				}
			});

		} catch (error) {
			console.error(error);
			sdl.push({
				system: {
					error: {
						type: error.name,
						message: error.message,
					}
				},
				event: 'system.error'
			})
		}
	}
})();
